<template>
	<div class="activity_admin">
		<div class="home_center">

			<div class="">
				<div class="center_left">

					<div class="left_tui">
						<div class="tui_title">
							<!-- 活动信息 -->{{$t('activity.HDXX')}}
						</div>
						<div class="tui_box">
							<div class="tui_list">
								<!-- 活动名称 -->{{$t('add_activity.HDMC')}}：{{eventInfo.event_name}}
							</div>
							<div class="tui_list">
								<!-- 发起者 -->{{$t('activity.FQZ')}}：{{eventInfo.open_name}}
							</div>
							<div class="tui_list">
								<!-- 发起时间 -->{{$t('activity.FQSJ')}}：{{$util.rTime(eventInfo.create_time)}}
							</div>
							<div class="tui_list">
								<div><!-- 活动时间 -->{{$t('activity.HDSJ')}}：</div>
								<div>
									<div>{{eventInfo.event_time_zone}}</div>
									<div><!-- 始 -->{{$t('START')}}：{{$util.rTime(eventInfo.event_start_time)}}</div>
									<div><!-- 终 -->{{$t('END')}}：{{$util.rTime(eventInfo.event_end_time)}}</div>
								</div>
							</div>
							<div class="tui_list">
								<!-- 集合地点 -->{{$t('activity.JHDD')}}：{{eventInfo.event_location}}
							</div>
							<div class="tui_list">
								<!-- 活动人数 -->{{$t('activity.HDRS')}}：{{eventInfo.event_num_people}}{{$t('REN')}}
							</div>
							<div class="tui_list">
								<!-- 参加限制 -->{{$t('activity.CJXZ')}}：{{eventInfo.event_type_method==0?$t('add_activity.WXZ'):$t('add_activity.XSH')}}
							</div>
							<router-link :to='"/edit_activity?event_id="+event_id'>
								<el-button type="primary" class="tui_btn"><!-- 编辑活动 -->{{$t('activity.BJHD')}}</el-button>
							</router-link>
						</div>
						
						<el-button type="primary" class="tui_change" @click="del"><!-- 删除 -->{{$t('activity.SC')}}</el-button>

					</div>
					<div class="left_tui">
						<div class="tui_title">
							<!-- 活动信息 -->{{$t('activity.HDXX')}}
						</div>
						<div class="tui_box">
							<div class="tui_list">
								{{eventInfo.event_desc}}

							</div>

						</div>

					</div>
				</div>

			</div>


			<div class="center_people" >
				<div class="people_top">
					<div class="top_btn">
						<el-badge :value="eventInfo.join_num_people" :hidden="eventInfo.join_num_people==0" class="item">
							<el-button type="primary" @click="tab(1)" :class="{btn1:showIndex==1,btn2:showIndex!=1}"><!-- 参与者 -->{{$t('activity.CYZ')}}</el-button>
						</el-badge>
						<el-badge :value="eventInfo.collect_count" :hidden="eventInfo.collect_count==0" class="item">
							<el-button type="primary"  @click="tab(2)" :class="{btn1:showIndex==2,btn2:showIndex!=2}"><!-- 感兴趣的 -->{{$t('activity.GXQD')}}</el-button>
						</el-badge>
					</div>

					<!-- <el-button type="primary" class="el_btn">
						{{$t('activity.QFXX')}}
					</el-button> -->
				</div>
				<div class="people_list"  v-loading="loading"  element-loading-background="rgba(0, 0, 0, 0.8)">
					<div class="list_box" v-for="item in list" :key="item.event_join_id">
						<img :src="item.avatar_url" class="list_img" alt="">
						<div class="list_text">
							<div class="text_name">
								{{item.open_name}}<template v-if="item.profession">/{{item.profession}}</template>
								<span>{{$util.rTime(item.create_time)}}</span>
							</div>
							<div class="text_msg ">
								{{item.description}}
							</div>
						</div>
						<div class="btn_right">
							<el-select v-model="item.join_status" :placeholder="$t('SHZT')" @change="change_status(item.join_status,item.event_join_id)" v-if="showIndex==1">
								<el-option v-for="item in joinStatus" :key="item.data_code" :label="item.data_value"
									:value="item.data_code/1">
								</el-option>
							</el-select>
							<!-- <el-dropdown>
								<span class="el-dropdown-link" style="color: #2D2D2E;">
									待审核<i class="el-icon-arrow-down el-icon--right"></i>
								</span>
								<el-dropdown-menu slot="dropdown">
									<el-dropdown-item v-for="item in joinStatus" :key="item.data_code">{{item.data_value}}</el-dropdown-item>
								</el-dropdown-menu>
							</el-dropdown> -->
							<el-button type="primary" class="btn1" @click.stop="senmsg(item.join_user_id||item.collect_user_id)"><!-- 发消息 -->{{$t('home.FDXX')}}</el-button>
						</div>
					</div>
					
					
				</div>
			</div>

			
			<!-- 消息列表 -->
			<Homeright></Homeright>
		</div>
		<!-- 回复弹窗 -->
		<!-- <Reply></Reply> -->
		<!-- 全部评论 -->
		<!-- <Pinlun></Pinlun> -->
	</div>
</template>

<script>
	import Homeright from '../../components/home/home_right.vue';
	import scroll from '@/utils/scroll.js';
	import {debounce} from '@/utils/debounce.js';
	export default {
		components: {
			Homeright,
		},
		data() {
			return {
				showIndex:1,
				event_id:'',
				loading:false,
				eventInfo:{},
				listData:{
					event_id:'',
					pageSize:15,
					currentPage:1,
				},
				isLast:0,
				list:[],
				joinStatus:[]
			};
		},
		
		created(){
			if(this.$route.query.event_id){
				this.event_id=this.$route.query.event_id;
				this.listData.event_id=this.$route.query.event_id;
				this.getDetail();
			}
			this.getUnit();
			this.getList();		
			scroll.scrollToBottom(this.getList);
			
		},
		methods:{
			senmsg(id){
				console.log(id);
				if(this.$local.get('t_k')){
					this.$emit('chat',{user_id:id});
				}else{
					let lang=this.$local.get('lang');
					this.$local.clear();
					this.$local.set('lang',lang);
					
					this.$router.push({path:'/login'});
				}
				
			},
			//改变参与者审核状态
			async change_status(status,id){
				console.log(status,id);
				let res=await this.$request.post('/api/event/updateJoinStatus',{event_join_id:id,join_status:status});
			},
			//获取字典数据
			async getUnit(){
				let res=await this.$request.get('/api/sysdict/getDictList');
				this.joinStatus=res.data.join_status.data;
			},
			//切换列表
			tab(n){
				this.showIndex=n;
				this.getList('reload');
			},
			async getList(t){
				
				let urls={1:'/api/event/manageJoinUserPage',2:'/api/event/manageCollectUserPage'};
				if(t=='reload'){
					this.listData.currentPage=1;
					this.isLast=0;			  	
				}
				if(this.isLast==1||this.loading){
					return;
				}
				this.loading=true;
				let res=await this.$request.post(urls[this.showIndex],this.listData);
				this.loading=false;
				
				if(t=='reload'){
					this.list=res.data.records;
				}else{
					if(res.data.records.length>0){
						this.list=[...this.list,...res.data.records];
					}
				}
				if(this.listData.currentPage>=res.data.totalPage){
					this.isLast=1;
				}else{
					this.listData.currentPage+=1;
				}
							
			},
			//删除
			async del(){
				this.$confirm(this.$t('QDSCM')+'?', this.$t('TISHI'), {
				  confirmButtonText: this.$t('CONFIRM2'),
				  cancelButtonText: this.$t('CANCEL2'),
				  type: 'warning'
				}).then(async () => {
					let res=await this.$request.get('/api/event/deleteEvent',{event_id:this.event_id});
				  this.$message({
					type: 'success',
					message: res.message
				  });
				  this.$router.go(-1);
				}).catch(() => {
				           
				});
			},
			async getDetail(){
				
				let res=await this.$request.get('/api/event/getEvent',{event_id:this.event_id});
				
				this.eventInfo=res.data;
				
			},
		}
	};
</script>

<style lang="less" scoped>
	.activity_admin {
		min-height: 100%;
		background-color: #212121;

		.center_left {
			width: 286px;
			min-height: 233px;
			// background: #212121;
			border-radius: 3px;
			color: #fff;

			// border: 2px solid #567BB6;
			// padding: 30px;
			// box-sizing: border-box;
			.btn {
				width: 286px;
				height: 51px;
				background: #212121;
				border-radius: 7px;
				border: 1px solid #567BB6;
				display: flex;
				align-items: center;
				justify-content: center;
				color: #fff;
				box-sizing: border-box;
				margin-bottom: 10px;
			}

			.btn:hover {
				width: 286px;
				height: 51px;
				background: #567BB6;
				border-radius: 7px;
				display: flex;
				align-items: center;
				justify-content: center;
				border: none;
				color: #fff;
				margin-bottom: 10px;
			}

			.active {
				width: 286px;
				height: 51px;
				background: #567BB6;
				border-radius: 7px;
				display: flex;
				align-items: center;
				justify-content: center;
				border: none;
				color: #fff;
				margin-bottom: 10px;
			}

			.el-button {
				margin-left: 0;
			}

			.left_user {
				width: 286px;
				min-height: 169px;
				border-radius: 7px;
				border: 1px solid #567BB6;
				box-sizing: border-box;
				display: flex;
				align-items: center;
				justify-content: center;
				margin-bottom: 20px;

				.user_img {
					width: 103px;
					height: 103px;
					margin-right: 25px;
					border-radius: 10px;
				}

				.user_right {
					width: 100px;

					.right_name {
						font-size: 15px;
						font-weight: bold;
						margin-bottom: 10px;
						overflow: hidden;
						text-overflow: ellipsis;
						white-space: nowrap;
					}

					.right_msg {
						font-size: 12px;
						margin-bottom: 10px;
						overflow: hidden;
						text-overflow: ellipsis;
						white-space: nowrap;
					}
				}
			}

			.left_tui {
				font-size: 15px;
				padding: 30px;
				background-color: #567BB6;
				border-radius: 3px;
				margin-bottom: 30px;

				.tui_title {
					font-weight: bold;
					margin-bottom: 10px;
				}

				.tui_box {
					padding: 20px 0;
					border-top: 1px solid #FFFFFF;
					line-height: 1.3;

					.tui_list {
						font-size: 12px;
						margin-bottom: 5px;
					}

					.tui_btn {
						width: 208px;
						height: 34px;
						background: #FFFFFF;
						border-radius: 3px;
						border: 1px solid #FFFFFF;
						display: flex;
						align-items: center;
						justify-content: center;
						color: #212121;
						margin-top: 10px;
					}
				}

				.tui_change {
					width: 208px;
					height: 34px;
					border-radius: 3px;
					display: flex;
					align-items: center;
					justify-content: center;
					background-color: #567BB6;
					border: 1px solid #FFFFFF;
				}

			}

		}

		.home_center {
			display: flex;
			justify-content: flex-end;
		}

		.center_people {
			padding: 20px;
			box-sizing: border-box;
			display: flex;
			flex-direction: column;
			align-items: center;
			padding-top: 0;

			.people_top {
				width: 878px;
				height: 69px;
				background: #F3F1EA;
				border-radius: 7px;
				display: flex;
				align-items: center;
				justify-content: space-between;
				padding: 0 20px;
				box-sizing: border-box;
				margin-bottom: 20px;

				.el_btn {
					background: #567BB6;
					border: none;
				}

				.top_btn {
					display: flex;
					width: 240px;
					justify-content: space-between;

					.btn1 {
						width: 111px;
						height: 43px;
						background: #567BB6;
						border-radius: 7px;
						display: flex;
						align-items: center;
						border: none;
						justify-content: center;
					}

					.btn2 {
						width: 111px;
						height: 43px;
						background: #F3F1EA;
						border: 1px solid #567BB6;
						color: #567BB6;
						border-radius: 7px;
						display: flex;
						align-items: center;
						justify-content: center;
					}
				}

				.top_left_box {
					width: 189px;
					display: flex;
					align-items: center;
					font-size: 15px;

					.top_left {
						width: 20px;
						height: 20px;
					}
				}

				.top_center {
					font-weight: bold;
				}

				.input_right {
					width: 189px;
				}

			}

			.people_list {
				width: 878px;
				box-sizing: border-box;
				padding: 20px;
				background: #F3F1EA;
				border-radius: 7px;

				.list_box {
					height: 84px;
					border-bottom: 1px solid #567BB6;
					display: flex;
					align-items: center;
					justify-content: space-between;

					.list_img {
						width: 43px;
						height: 43px;
						margin-right: 15px;
					}

					.list_text {
						flex:1;

						.text_name {
							display: flex;
							align-items: center;
							font-size: 14px;
							color: #2D2D2E;
							margin-bottom: 10px;
							font-weight: bold;

							span {
								font-weight: normal;
								font-size: 12px;
								margin-left: 10px;
							}
						}

						.text_msg {
							width: 500px;
							font-size: 12px;
							line-height: 1.3;
							text-overflow: ellipsis;
							overflow: hidden;
							white-space: nowrap;
						}
					}

					.btn_right {
						display: flex;
						align-items: center;
						.el-input__inner{background: none;border:none;width: 90px;}
						.more {
							width: 27px;
							height: 27px;
							margin-left: 15px;
						}

						.btn1 {
							
							height: 34px;
							background: #567BB6;
							border-radius: 7px;
							display: flex;
							align-items: center;
							justify-content: center;
							border: none;
							margin-left: 10px;
						}

						.btn2 {
							
							height: 34px;
							border: 1px solid #567BB6;
							background-color: #F3F1EA;
							color: #2D2D2E;
							border-radius: 7px;
							display: flex;
							align-items: center;
							justify-content: center;
							margin-left: 10px;
						}

						.btn3 {
							
							height: 34px;
							border: 1px solid #B65664;
							background-color: #F3F1EA;
							color: #B65664;
							border-radius: 7px;
							display: flex;
							align-items: center;
							justify-content: center;
						}
					}
				}

				.list_none {
					text-align: center;
					font-size: 14px;
					line-height: 40px;
					font-weight: bold;
					color: #2D2D2E;
				}
			}

		}
	}
</style>
